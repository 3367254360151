import React from 'react'


const Header : React.FC = () => {


  return (
    <div className="menu-nav-container">
    <img
      src="/Vrality_Logo_Gradient_Mesa de trabajo 1.png"
      alt=""
      id="logo"
    />
    <nav className="hide menu-toggle menu-first-page">
      <div className="menu-toggle" id="menu-toggle">
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
      <ul id="menu-items" className="hide-menu">
        <li>
          <a href="#home">Home</a>
        </li>
        <li>
          <a href="#aidrop">Airdrop</a>
        </li>
        <li>
          <a href="#about-vrality">About Vrality</a>
        </li>
        <li>
          <a href="#about-vanguard">About Vanguard</a>
        </li>
        <li>
          <a href="#mkt-prices">MKT Prices</a>
        </li>
        <li>
          <a href="#tokenomics">Tokenomics</a>
        </li>
        <li>
          <a href="#faq">FaQ</a>
        </li>
      </ul>
    </nav>
  </div>
  )
}


export default Header;
