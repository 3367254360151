import React, {useState, useEffect} from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapter, WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  // getTorusWallet,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import Header from './components/Header';
import Modal from './components/Modal';
import PriceNAction from './components/PriceNAction';
import Notifier from './components/Notifier';
import { Buffer } from "buffer";


declare global {
  interface Window {
      Buffer: typeof Buffer;
  }
}


function App() {

  const  [show, setShow] = useState<boolean>(false);
  const [notify, setNotify] = useState<boolean>(false);
  const [notifyType, setNotifyType] = useState<string>(""); //it can be success, error, warn
  const [notifymsg, setNotifymsg] = useState<string>("");

  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  //currently as Mainnet
  //Then there is testnet
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint
  const endpoint = React.useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = React.useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );

  // Create an array of Adapter objects
  // const adapters: Adapter[] = wallets.map((wallet) => {
  //   return new BaseWalletAdapter(wallet);
  // });

  //            <MyWallet />

  //  Type 'string' is not assignable to type 'boolean | undefined'.

  useEffect(() => {
      (window as any).Buffer = Buffer;
      if (notify) {
        setTimeout(() => {
          setNotify(false);
          setNotifymsg("");
        }, 3500);
      }
  }, [notify])
  

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} >
      <div className="main-section">
          <video autoPlay={true} muted={true} loop={true} id="background-video">
            <source src="/videos/vanguard-space-ship-video.mp4" type="video/mp4" />
          </video>
          <Header />
          { show && <Modal setShow={setShow} />}
          <PriceNAction 
            setShow={setShow} 
            show={show} 
            setNotify={setNotify} 
            setNotifymsg={setNotifymsg} 
            setNotifyType={setNotifyType} 
            />
        </div>
        <div className="partner-logos-section">
          <div className="carousel">
            <img src="/CoinMarketCap.png" alt="" />
            <img src="/CoinMarketCap.png" alt="" />
            <img src="/CoinMarketCap.png" alt="" />
            <img src="/CoinMarketCap.png" alt="" />
            <img src="/CoinMarketCap.png" alt="" />
          </div>
        </div>
        {notify && <Notifier notifyType={notifyType} notifymsg={notifymsg} />}
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
