import React from 'react';

interface ModalProps {
  setShow: (show: boolean) => void;
}


const Modal : React.FC<ModalProps> = ({setShow}) => {
  return (
    <div id="myModal" className="modal">
    <div className="modal-content">
      <span onClick={() => setShow(false)} className="close">×</span>
      <div className="title-logo">
        <img src="img/Logo_Vanguard_WebSite-01.png" alt="" />
      </div>
      <div className="info-content">
        <p>
          Your investment not only drives Vanguard's development, it also puts
          you at the forefront of the next gaming revolution. With every
          contribution, we're forging a more immersive gaming world, filled
          with exciting features and unique experiences that will take your
          breath away.
          <br />
          <br />
          As an investor, you're not just backing a project; you're securing
          your place at the front row of the action. With the game set to
          enter beta mode in just 20 weeks, you'll have the exclusive
          opportunity to explore the Vanguard universe before anyone else -
          get ready for an epic experience that will forever change your
          perception of gaming!
          <br />
          <br />
          Join us and be part of the future of digital entertainment!
        </p>
      </div>
      <div className="partner-logos">
        <div>
          <img
            src="img/Partner-Logos-Vrality_Mesa de trabajo 1_Mesa de trabajo 1.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  )
}

export default Modal;