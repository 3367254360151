import React, { useState, ChangeEvent, useEffect  } from 'react';
import MyWallet from "../MyWallet";
import {
  WalletNotConnectedError,
  SignerWalletAdapterProps
} from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
  createTransferInstruction,
  createAssociatedTokenAccountInstruction,
  getAssociatedTokenAddress,
  getAccount
} from '@solana/spl-token';
// import { createTransferInstruction } from '@solana/spl-token';

import {
  PublicKey,
  Transaction,
  Connection,
  TransactionInstruction
} from '@solana/web3.js';
import { LoaderSvg } from '../utils/usefulComponents';
import { makeCall } from '../utils/makeCall';
import { LOCAL_URL, PRODUCTION_URL } from '../utils/constants';



interface PriceNActionProps {
  setShow: (show: boolean) => void;
  show: boolean;
  setNotify: (show: boolean) => void;
  setNotifyType: (show: string) => void;
  setNotifymsg: (show: string) => void;
}


export const configureAndSendCurrentTransaction = async (
  transaction: Transaction,
  connection: Connection,
  feePayer: PublicKey,
  signTransaction: SignerWalletAdapterProps['signTransaction']
) => {
  const blockHash = await connection.getLatestBlockhash();
  transaction.feePayer = feePayer;
  transaction.recentBlockhash = blockHash.blockhash;
  const signed = await signTransaction(transaction);
  const signature = await connection.sendRawTransaction(signed.serialize());
  await connection.confirmTransaction({
    blockhash: blockHash.blockhash,
    lastValidBlockHeight: blockHash.lastValidBlockHeight,
    signature
  });
  return signature;
};



const PriceNAction : React.FC<PriceNActionProps> = ({setShow, show, setNotify, setNotifyType, setNotifymsg}) => {
    const { connection } = useConnection();
    const { publicKey, signTransaction } = useWallet();
    const [amountSelected, setAmountSelected] = useState<number>(0);
    const [amountCalculated, setAmountCalculated] = useState<number>(0);
    const [loading, setLoading] = useState<Boolean>(false);
    const [user, setUser] = useState<any>();
    const [pool, setPool] = useState<Number>();
    
    //address
    // const [walletAddressState, setWalletAdressState] = useState<String>("");
    
    //transactions
    const [txSave, SetTxSave] = useState<string>("");
     let walletAddress= ""
    const wallet = useWallet();
    if (wallet.connected && wallet.publicKey) {
      walletAddress = wallet.publicKey.toString()
      // setWalletAdressState(wallet.publicKey.toString())
    }
    // if (wallet.connected && wallet.publicKey) {
        // walletAddress = wallet.publicKey.toString()


    const transfer = async () => {
     try {
      setLoading(true);
      if (!publicKey || !signTransaction) {
        // Handle wallet not connected
        setNotify(true);
        setNotifyType("warn");
        setNotifymsg("connect wallet");
        setLoading(false);
        return;
      }

      if(amountSelected === 0) {
        // Handle
        setNotify(true);
        setNotifyType("warn");
        setNotifymsg("cannot send 0 amount");
        setLoading(false);
        return;
      }
      
      //usdc  Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr
      //usdt  Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB
      const mintToken = new PublicKey('Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr');
      // const usdtToken = new Token(connection, USDT_TOKEN_MINT_ADDRESS, TOKEN_PROGRAM_ID, null);

      // Get ATA for recipient 
      const recipientAddress = new PublicKey("23aXF9mDxokeGG5hUXZjahtu7eMdUUEedpxJhqKSte9N");


      const transactionInstructions: TransactionInstruction[] = [];
      // get or create associated tokenAccount's (SRM) for the receiver and sender
      const associatedTokenFrom = await getAssociatedTokenAddress(
        mintToken,
        publicKey
      );
      const fromAccount = await getAccount(connection, associatedTokenFrom);

      const associatedTokenTo = await getAssociatedTokenAddress(
        mintToken,
        recipientAddress
      );
      

      if (!(await connection.getAccountInfo(associatedTokenTo))) {
        transactionInstructions.push(
          createAssociatedTokenAccountInstruction(
            publicKey,
            associatedTokenTo,
            recipientAddress,
            mintToken
          )
        );
      }
      //push
      transactionInstructions.push(
        createTransferInstruction(
          fromAccount.address, // source
          associatedTokenTo, // dest
          publicKey,
          amountCalculated // transfer 1 USDC, USDC on solana devnet has 6 decimal
        )
      );
      // Finally do the transfer
      const transaction = new Transaction().add(...transactionInstructions);
      const signature = await configureAndSendCurrentTransaction(
        transaction,
        connection,
        publicKey,
        signTransaction
      );
      // signature is transaction address, you can confirm your transaction on 'https://explorer.solana.com/?cluster=devnet'
      SetTxSave(signature);
      setNotify(true);
      setNotifyType("success");
      setNotifymsg("Gotten MKT");
      setLoading(false);

      await AddAndUpdateInvestor();
     } catch (error) {
      console.log(error);
      
     }
    }
    

    const calculate = (from: string, e: ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(e.target.value);
      if (from === "mkt") {
          setAmountCalculated(value);
          setAmountSelected(parseFloat((value / 2).toFixed(2)));
      } else {
          setAmountSelected(value);
          setAmountCalculated(parseFloat((value * 2).toFixed(2)));
      }
  }


  const fetchAddressData = async () => {
    console.log("Iwant to know oooo");
    try {
      console.log("inside try");
      const url = `${PRODUCTION_URL}/investor/${walletAddress}`;
      const headers = {
            "Content-Type": "application/json",
      }
      const response = await makeCall(url, {}, headers, "get");
      console.log(response, "here twenty three two");

      if (response.status) {
        setUser(response.data)
      } 
    } catch (error) {
      console.log("checking oooo fetch", error);
    }
  };

  const fetchPoolData = async () => {
    console.log("Iwant to know oooo");
    try {
      console.log("inside try");
      const url = `${PRODUCTION_URL}/poolprogress`;
      const headers = {
            "Content-Type": "application/json",
      }
      const response = await makeCall(url, {}, headers, "get");
      console.log(response, "here twenty three one");

      if (response.status) {
        setPool(response.data)
      } 
    } catch (error) {
      console.log("checking oooo fetch", error);
    }
  };


  const AddAndUpdateInvestor = async () => {
    try {

      const endpoint = `${PRODUCTION_URL}/addinvestor`;
      // console.log( socketData, "Oya na" );
      const data = {
        address: walletAddress,
        txhash: txSave,
        mkt: amountCalculated,
        usdt: amountSelected,
      };
      const headers = {
           "Content-Type": "application/json",
      }
    const response = await makeCall(endpoint, data, headers, "post")

    console.log(response, "checking response");

    if (response.status) {
      // setNotify(true);
      // setNotifyType("success");
      // setNotifymsg(" updated");
      setUser(response.data);
      return;
    } else {
      // setNotify(true);
      // setNotifyType("warn");
      // setNotifymsg(response.message);
      return;
    }
    } catch (error) {
      console.log("checking oooo update");
    }
  };


  useEffect(() => {
    if(!user && wallet.connected) {
      fetchAddressData();
    }
    if(!pool && wallet.connected){
      fetchPoolData()
    }
  }, [loading, wallet.connected])
  

  return (
    <div className="main-invest-container" id="invest-container">
    <div className="h1-container">
      <h1>Private Token Sale</h1>
      <p>
        This is a unique offer where tokens acquired here will have no vesting
        type. Take advantage of this exclusive opportunity to acquire tokens
        without any vesting restrictions.
      </p>
      <button onClick={() => setShow(!show) } className="btn-hover color-3" id="readMebutton">
        Read me
      </button>
    </div>
    <div className="invest-container" id="investContainer">
      <div className="invest-title-container">
        <h2>BUY IN BEFORE PRICE INCREASES!</h2>
      </div>
      <div className="countdown-container" id="timer" />
      <div className="container" id="container">
        <div className="progress2 progress-moved" id="progressContainer">
          <div className="progress-bar2" />
          <div className="loader" style={{}} />
        </div>
      </div>
      
      <div className="price-details" id="priceDetails">
        <div className="price-details-title">
          <p>
            This private sale is intended to raise funds for Vanguard's
            continued development.
          </p>
        </div>
        <div className="raised-progress" id="progress">
          <p>
            <span style={{ fontWeight: "bold" }}>USDT RAISED:</span> $
            <span id="raisedAmount">0</span> / $50,000
          </p>
        </div>
        <div className="your-tokens-in-mkt">
          <p>
            <span style={{ fontWeight: "bold" }}>YOUR PURCHASED MKT</span> ={" "}
            <span id="tokens">{user?.mkt}</span>
          </p>
        </div>
      </div>
      <div className="token-price" id="tokenPrice">
        <p className="token-text">1 MKT = $0.3808</p>
      </div>
      <div className="input-container">
        <div className="label-container">
          <label htmlFor="usdt">Pay with USDT</label>
          <div className="input-with-icon">
            <input
              type="number"
              id="lbl-usdt"
              name="lbl-usdt"
              className="input-with-icon"
              value={amountSelected}
              onChange={(e) => calculate("usdt", e)}
              pattern="[0-9]"
            />
            <span className="icon" />
          </div>
        </div>
        <div className="label-container">
          <label htmlFor="mkt-token">Recive MKT</label>
          <div className="input-with-icon">
            <input
              type="number"
              id="lbl-mkt-token"
              name="lbl-mkt-token"
              className="input-with-icon"
              value={amountCalculated}
              onChange={(e) => calculate("mkt", e)}
              pattern="[0-9]"
            />
            <span className="icon" />
          </div>
        </div>
      </div>
      <div id="if-wallet-connected">
        {wallet.connected && walletAddress }
      </div>
      <div className="button-section" style={{marginTop: "8px"}}>
        {
           wallet.connected ?
              <button
              className="btn-hover color-3"
              id="connectButton"
              onClick={transfer}
            >
              {
                loading ?
                <LoaderSvg />
                :
                 "transfer"
              }
            </button> 
           :
            <MyWallet />
        }

      </div>
    </div>
  </div>
  )
}


export default PriceNAction;